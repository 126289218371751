

html,
body,
#root,
.ms-Fabric,
#o-wrapper {
  min-height: 100vh;
}

.duplidoc-purple-light-bg {
  background-color: #006db3 !important;
}
.duplidoc-purple-bg {
  background-color: #039be5 !important;
}
.duplidoc-purple-light {
  color: #006db3 !important;
}
.duplidoc-purple {
  color: #039be5 !important;
}
.padding {
  padding: 15px;
}

.btn-secondary.duplidoc-purple-light-bg:focus,
.toggle-selection-btn:focus {
  outline: none;
  box-shadow: none;
}

#selected-text {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  height: 100px;
  background-color: #deecf9;
}
.toggle-bindings {
  display: flex;
}

.edit-button {
  font-size: 11px;
}

.btn-highlight:focus {
  font-weight: inherit;
  border: none;
  outline: none;
}

.btn-highlight.active {
  font-weight: normal;
  color: #999;
}

.btn-remove-bindings {
  color: #fff;
}

.btn-back {
  margin-top: 10px;
}

.version-info {
  color: #ae9dcc;
  text-align: right;
  padding: 10px;
}

i.cog-icon {
  font-size: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.btn-dependent {
  color: #fff;
  margin: 10px 0;
}

.btn-dependent.enable {
  background-color: #868e96;
}

.rule-select {
  padding: 5px 10px;
  margin-bottom: 10px;
}

.dependent-option:hover {
  cursor: pointer;
  font-weight: bold;
}

.sub-rules {
  margin-top: 10px;
}

.sub-rules .title {
  font-size: 1.2em;
}

.btn-xs {
  font-size: 10px;
  padding: 2px 6px;
  line-height: 1.2;
  position: relative;
  top: -2px;
}

textarea.freetextarea {
  display: block;
  width: calc(100% - 25px);
  padding: 5px;
  border: 1px solid #ddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  line-height: 20px;
}

.ms-Icon.icon-dependent {
  font-size: 16px;
  margin-left: 5px;
  color: #000;
  position: relative;
  top: 3px;
}

.toggle-list .rule-toggle-group .btn {
  border-radius: 0;
}

.toggle-list .toggle {
  margin: 5px 0;
}

.toggle-list .toggle h6 {
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
  margin-top: 1em;
}

.toggle-list .toggle .list-group-item {
  padding: 0.5rem 1rem;
}

.toggle-list .toggle .list-group-item > div {
  align-items: center;
}
.toggle-list .toggle .list-group-item > div > * {
  margin-right: 15px;
}

.edit-modal {
  display: block;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

.edit-modal .modal-dialog {
  margin-top: 50px;
}

.toggle-btn-container .btn {
  flex: 1 1;
}

.toggle-btn-container:last-child {
  margin-bottom: 1em;
}

.toggle-btn-container:first-child .btn {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.toggle-btn-container:last-child .btn {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.vt-buttons {
  border-radius: 5px;
  overflow: hidden;
}

.vt-buttons .btn {
  border: none;
  border-radius: 0;
  overflow: hidden;
}

.btn-text-update {
  margin: 5px 10px 0 0;
  padding: 5px 10px;
}

/**
 * Loading indicator
 */
#o-wrapper {
  position: relative;
}

#load-status {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  z-index: 9;
}

/*
* Flex modal
*/
.rule-title-row {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

